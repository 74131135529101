<script>
  import Layout from "../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import SettingsSide  from "@/components/settings-side.vue";
  import appConfig from "../../../app.config";
  import { callUrl, saveEntity, updateEntity } from '@/helpers/api-apolo';
  import {saError, saSuccess} from '@/helpers/global-functions';
  import { overlay } from "@/state/helpers";
  import Swal from "sweetalert2";



export default {
  page: {
    title: "Usuários",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Usuários",
      items: [
        {
            text: "Configurações",
            href: "/"
        },
        {
          text: "Usuários",
          active: true,
        },
      ],
      searching: false,
      query: '',
      page: 1,
      perPage: 10,
      users: [],
      user: {
        id: '',
        name: '',
        profile: 'atendente',
        email: '',
        telephone: ''
      }
    };
  },
  name: "Usuários",
  components: {
    Layout,
    PageHeader,
    SettingsSide
  },
  computed: {
    formValid() {
      return !(this.user.name === '' || this.user.email === '' || this.user.telephone === '');
    },
    pages() {
      if (this.page === 1 && !this.users.length) {
        return [];
      }
      if (this.page === 1 && this.users.length < this.perPage) {
        return [1];
      }
      if (this.page === 1 && this.users.length === this.perPage) {
        return [1, 2];
      }
      if (this.page > 1 && (!this.users.length || this.users.length < this.perPage)) {
        return [this.page - 1, this.page];
      }
      return [this.page - 1, this.page, this.page + 1];
    }
  },
  watch: {
    page() {
      this.getUsers();
    },
    query(value) {
      if (value === '' || value.length === 0) {
        this.getUsers(true)
      }
    }
  },
  methods: {
    ...overlay,
    formatProfileLabel(profile) {
      switch (profile) {
        case 'master':
          return 'Gestor';
        case 'coordenador':
          return 'Coordenador';
        default:
          return 'Atendente';
      }
    },
    formValidation(val) {
      return ['form-control',{'is-invalid': !val || val === ''},{'is-valid': val && val !== ''}]
    },
    selectUser(user) {
      this.user = user;
    },
    resetForm() {
      this.user = {
        id: '',
        name: '',
        profile: 'atendente',
        email: '',
        telephone: ''
      }
    },
    async createUser() {
      try {
        if (!this.formValid) {
          saError("Preencha todos os campos obrigatórios!")
          return;
        }
        this.changeShowOverlay({show: true});
        let saveBody = JSON.parse(JSON.stringify(this.user));
        let result = {}
        if (saveBody.id) {
          result = await updateEntity(saveBody, 'user')
        } else {
          result = await saveEntity(saveBody, 'user')
        }
        this.changeShowOverlay({show: false});
        if (result.id) {
          saSuccess("Usuário salvo com Sucesso!")
          this.user = {
            id: '',
            name: '',
            profile: 'atendente',
            email: '',
            telephone: ''
          }
          await this.getUsers()
        }
      } catch (error) {
        this.changeShowOverlay({show: false});
        console.log(error.message)
        saError(error.message)
      }
    },
    search() {
      if (this.page !== 1) {
        this.page = 1;
      } else {
        this.getUsers();
      }
    },
    async getUsers() {
      try {
        if (this.searching) {
          return;
        }
        this.searching = true;
        this.changeShowOverlay({show: true});
        this.users = [];
        const params = [`page=${this.page}`, `limit=${this.perPage}`];
        if (this.query.length > 0) {
          params.push(`query=${this.query}`);
        }
        const dataM = await callUrl({}, '/user?' + params.join('&'), 'get')
        if (dataM && dataM.length) {
          for (let i = 0; i < dataM.length; i++) {
            let obj = dataM[i];
            if (!obj.avatar) {
              obj.avatar = require('@/assets/images/users/user-dummy-img.jpg');
            }
            obj.validated = 'Pendente';
            if (obj.validated_at && obj.validated_at !== '') {
              obj.validated = 'OK';
            }
            obj.profile_label = this.formatProfileLabel(obj.profile);
            this.users.push(obj);
          }
        }
      } catch (error) {
        saError(error.message);
      } finally {
        this.searching = false;
        this.changeShowOverlay({show: false});
      }
    },
    async deleteUser(user) {
      Swal.fire({
        title: `Você tem certeza que deseja remover o usuário "${user.name}"?`,
        html: "Essa é uma operação irreversível!",
        icon: "warning",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Sim, pode remover!",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
        customClass: {
          actions: 'd-flex justify-content-center',
          confirmButton: 'btn btn-success flex-grow-1',
          cancelButton: 'btn btn-outline-dark flex-grow-1'
        }
      }).then(async (result) => {
        if (result.value) {
          try {
            this.changeShowOverlay({show: true});
            await callUrl({}, '/user/' + user.id, 'delete');
            this.changeShowOverlay({show: false});
            Swal.fire("Sucesso!", "Usuário removido com sucesso", "success");
            this.getUsers()
          } catch (error) {
            this.changeShowOverlay({show: false});
            saError(error.message);
          }
        }
      });
    },
    async resentValidation(user) {
      Swal.fire({
        title: `Você tem certeza que deseja reenviar a validação ao usuário "${user.name}"?`,
        html: "Ele receberá um e-mail de boas vindas e um link par avalidação.",
        icon: "warning",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Sim, pode reenviar!",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
        customClass: {
          actions: 'd-flex justify-content-center',
          confirmButton: 'btn btn-success flex-grow-1',
          cancelButton: 'btn btn-outline-dark flex-grow-1'
        }
      }).then(async (result) => {
        if (result.value) {
          try {
            this.changeShowOverlay({show: true});
            await callUrl({}, `/user/${user.id}/resent-validation`, 'post');
            this.changeShowOverlay({show: false});
            Swal.fire("Sucesso!", "validação reenviada com sucesso", "success");
          } catch (error) {
            this.changeShowOverlay({show: false});
            saError(error.message);
          }
        }
      });
    }
  },
  async mounted() {
    await this.getUsers();
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-sm-12 col-lg-2 col-md-3">
          <SettingsSide :actualRoute="'users'"></SettingsSide>
        </div>
        <div class="col-sm-12 col-lg-10 col-md-9">
          <div class="card">
            <div class="card-header border-0">
              <div class="d-flex align-items-center">
                <h5 class="card-title mb-0 flex-grow-1">Gerenciar Usuários</h5>
                <div class="flex-shrink-0">
                  <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#addmembers">
                      <i class="ri-add-fill me-1 align-bottom"></i> Usuário
                    </button>
                </div>
              </div>
            </div>
            <div class="card-body bg-soft-light border border-dashed border-start-0 border-end-0">
              <div class="row g-3">
                <div class="col-sm-4">
                  <div class="search-box">
                    <input type="text" class="form-control" v-model="query" placeholder="Pesquisar..." @keydown.enter.prevent="search()" />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>
                <div class="col-sm-6"></div>
                <div class="col-sm-2">
                  <button type="button" class="btn btn-primary w-100" @click="search()">
                    <i class="mdi mdi-magnify me-1 align-bottom"></i> Buscar
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive table-card">
                  <table class="table table-striped align-middle" id="customerTable">
                    <thead class="table-primary">
                      <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Telefone</th>
                        <th>Atribuição</th>
                        <th>Validado</th>
                        <th style="text-align:end;">Ação</th>
                      </tr>
                    </thead>
                    <tbody class="list form-check-all">
                      <tr v-for="(user, key) in users" :key="'user-' + key">
                        <td>
                          <div class="team-profile-img" style="display: flex">
                            <div class="avatar-sm img-thumbnail rounded-circle flex-shrink-0">
                              <img :src="user.avatar" alt="" class="img-fluid d-block rounded-circle">
                            </div>
                            <span class="fs-12 mb-1" style="align-items: center;display: flex;justify-content: center;margin-left: 5px;">{{user.name}}</span>
                          </div>
                        </td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.telephone }}</td>
                        <td>{{ user.profile_label }}</td>
                        <td>{{ user.validated }}</td>
                        <td style="text-align:end;">
                          <!--b-button variant="info" class="btn-icon waves-effect waves-light" style="margin-right: 5px;"><i class="ri-pencil-line"></i></b-button-->
                          <b-button v-if="!user.validated_at" @click.prevent="resentValidation(user)" title="Reenviar validação" variant="info" class="btn-icon waves-effect waves-light" style="margin-right: 5px;"><i class="mdi mdi-email-sync"></i></b-button>
                          <b-button @click.prevent="deleteUser(user)" title="Remover" variant="danger" class="btn-icon waves-effect waves-light" style="margin-right: 5px;"><i class="ri-delete-bin-5-line"></i></b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                <EmptyTableResult v-show="users.length == 0" />
                <div class="d-flex justify-content-end m-3">
                  <div class="pagination-wrap hstack gap-2">
                    <a class="page-item pagination-prev" href="#" @click="--page" v-if="page > 1">Anterior</a>
                    <a class="page-item pagination-prev disabled" href="#" v-if="users.length && page == 1" @click.prevent="">Anterior</a>
                    <ul class="pagination listjs-pagination mb-0">
                      <li v-for="pageNumber in pages" :key="'page' + pageNumber" @click="page = pageNumber"
                        :class="{active: pageNumber == page, disabled: pageNumber != page}">
                        <a class="page" href="#">{{ pageNumber }}</a>
                      </li>
                    </ul>
                    <a class="page-item pagination-next" href="#" @click="++page" v-if="users.length == perPage">Próxima</a>
                    <a class="page-item pagination-next disabled" href="#" @click.prevent="" v-if="users.length && users.length < perPage">Próxima</a>
                  </div>
                </div>
              </div>

              <!-- Modal -->
              <div
                class="modal fade"
                id="addmembers"
                tabindex="-1"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="myModalLabel">Criar Usuário</h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <form>
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="mb-3">
                              <label for="teammembersName" class="form-label">Nome</label>
                              <input
                                type="text"
                                :class="formValidation(user.name)"
                                id="teammembersName"
                                v-model="user.name"
                                placeholder="Informe o nome"
                                required
                              />
                              <div class="invalid-feedback">Campo Obrigatório</div>
                              <div class="valid-feedback">Ok</div>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div class="mb-3">
                              <label for="designation" class="form-label">Atribuição</label>
                              <select class="form-select mb-3" aria-label="Default select" v-model="user.profile" required>
                                  <option value="atendente">Atendente</option>
                                  <option value="coordenador">Coordenador</option>
                                  <option value="master">Gestor</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div class="mb-3">
                              <label for="email" class="form-label">Email</label>
                              <input
                                type="email"
                                :class="formValidation(user.email)"
                                id="email"
                                v-model="user.email"
                                placeholder="Informe o Email"
                                required
                              />
                              <div class="invalid-feedback">Campo Obrigatório</div>
                              <div class="valid-feedback">Ok</div>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div class="mb-3">
                              <label for="telephone" class="form-label">Telefone</label>
                              <input
                                type="tel"
                                :class="formValidation(user.telephone)"
                                id="telephone"
                                v-model="user.telephone"
                                placeholder="Informe o Telefone"
                                v-maska="['+##############', '+#############', '+############', '+###########', '+##########']"
                                required
                              />
                              <div class="invalid-feedback">Campo Obrigatório</div>
                              <div class="valid-feedback">Ok</div>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div class="hstack gap-2 justify-content-end">
                              <button
                                type="button"
                                class="btn btn-light"
                                data-bs-dismiss="modal"
                                @click.prevent="resetForm"
                              >
                                Cancelar
                              </button>
                              <button type="submit" class="btn btn-success" data-bs-dismiss="modal"  @click.prevent="createUser" :disabled="!formValid">
                                Criar Usuário
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <!--end modal-content-->
                </div>
                <!--end modal-dialog-->
              </div>
              <!--end modal-->

              <div
                class="offcanvas offcanvas-end border-0"
                tabindex="-1"
                id="offcanvasExample"
              >
                <!--end offcanvas-header-->
                <div class="offcanvas-body profile-offcanvas p-0 mt-3">
                  <div class="p-3 text-center">
                    <img
                      :src="user.avatar"
                      alt=""
                      class="avatar-lg img-thumbnail rounded-circle mx-auto"
                    />
                    <div class="mt-3">
                      <h5 class="fs-15">
                        <a href="javascript:void(0);" class="link-primary"
                          >{{user.name}}</a
                        >
                      </h5>
                      <p class="text-muted">{{user.profile_label}}</p>
                    </div>
                  </div>
                  <div class="row g-0 text-center">
                    <div class="col-6">
                      <div class="p-3 border border-dashed border-start-0">
                        <h5 class="mb-1">124</h5>
                        <p class="text-muted mb-0">Pipelines</p>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-6">
                      <div class="p-3 border border-dashed border-start-0">
                        <h5 class="mb-1">81</h5>
                        <p class="text-muted mb-0">Negócios</p>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                  <div class="p-3">
                    <h5 class="fs-15 mb-3">Dados do Usuário</h5>
                    <div class="mb-3">
                      <p class="text-muted text-uppercase fw-semibold fs-12 mb-2">
                        Telefone
                      </p>
                      <h6>+55 11 99938-6810</h6>
                    </div>
                    <div class="mb-3">
                      <p class="text-muted text-uppercase fw-semibold fs-12 mb-2">
                        Email
                      </p>
                      <h6>{{user.email}}</h6>
                    </div>
    <!--                <div>-->
    <!--                  <p class="text-muted text-uppercase fw-semibold fs-12 mb-2">-->
    <!--                    Location-->
    <!--                  </p>-->
    <!--                  <h6 class="mb-0">Carson City - USA</h6>-->
    <!--                </div>-->
                  </div>
                </div>
                <!--end offcanvas-body-->
    <!--            <div-->
    <!--              class="-->
    <!--                offcanvas-foorter-->
    <!--                border-->
    <!--                p-3-->
    <!--                hstack-->
    <!--                gap-3-->
    <!--                text-center-->
    <!--                position-relative-->
    <!--              "-->
    <!--            >-->
    <!--              <button class="btn btn-light w-100">-->
    <!--                <i class="ri-question-answer-fill align-bottom ms-1"></i> Send-->
    <!--                Message-->
    <!--              </button>-->
    <!--              <router-link to="/pages/profile" class="btn btn-primary w-100"-->
    <!--                ><i class="ri-user-3-fill align-bottom ms-1"></i> View-->
    <!--                Profile</router-link-->
    <!--              >-->
    <!--            </div>-->
              </div>
              <!--end offcanvas-->
            </div>
          </div>
        </div>
      <!-- end col -->
    <!--end row-->
      </div>

    <svg class="bookmark-hide">
      <symbol
        viewBox="0 0 24 24"
        stroke="currentColor"
        fill="var(--color-svg)"
        id="icon-star"
      >
        <path
          stroke-width=".4"
          d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
        ></path>
      </symbol>
    </svg>
  </Layout>
</template>
